<script setup>
import Button from '@/Components/Button/Button.vue';
import Modal from '@/Components/Popup/Modal.vue';
import Title from '@/Components/Title/Title.vue';
import { useForm } from '@inertiajs/vue3';
import { computed, inject } from 'vue';

const route = inject('route');
const props = defineProps({
    showing: Boolean,
    shift: Object,
    application: Object,
});
const dayjs = inject('dayjs');
const emits = defineEmits(['update:showing']);
const closeForm = () => emits('update:showing', false);

const absentForm = useForm({
    application_id: '',
});

const sendAbsent = () => {
    absentForm.application_id = props.application.id;
    absentForm.post(route('location.absent-applications.store'), {
        onSuccess: closeForm,
    });
};

const illnessForm = useForm({
    application_id: '',
});

const sendIllness = () => {
    illnessForm.application_id = props.application.id;
    illnessForm.post(route('location.illness-applications.store'), {
        onSuccess: closeForm,
    });
};

const noShowForm = useForm({
    application_id: '',
});

const sendNoShow = () => {
    noShowForm.application_id = props.application.id;
    noShowForm.post(route('location.no-show-applications.store'), {
        onSuccess: closeForm,
    });
};

const shiftInThePast = computed(() => {
    return dayjs(props.shift.start).isBefore();
});
</script>

<template>
    <Modal :open="showing" @closing="closeForm">
        <template #default>
            <Title :title="$t('Report an absence')" />
            <p class="mt-3 text-base text-blue">

                {{ $t('If the Fl@xr was absent due to an unforeseen reason, click on report absent.') }} <br>
                {{ $t('If the Fl@xr was absent after calling in sick, click call in sick.') }} <br>
                    <span v-if="shiftInThePast">
                        {{ $t('If the applicant did not show up, please click report a no show') }} <br>
                    </span>


            </p>
        </template>
        <template  #button-bar>
            <Button :processing='absentForm.processing' blue fullWidth @click='sendAbsent'>
                {{ $t('Report absentee') }}
            </Button>
            <Button :processing='illnessForm.processing' fullWidth orange @click='sendIllness'>
                {{ $t('Report illness') }}
            </Button>

            <Button v-if='shiftInThePast' :processing='noShowForm.processing' danger fullWidth @click='sendNoShow'>
                {{ $t('Report no show') }}
            </Button>

            <Button @click='closeForm'>
                {{ $t('Cancel') }}
            </Button>
        </template>


    </Modal>
</template>
